import React from 'react';
import { Link } from 'gatsby';

import * as Routes from '../../constants/routes';

import IconTable from '../../assets/images/cfs-shedule-table.inline.svg';
import IconClock from '../../assets/images/cfs-shedule-clock.inline.svg';

function CFS({
  isLarge = false,
  title,
  description,
  subsctribeBtn = '',
  subscribeBtnRoute = Routes.INSCRIPCION_ROUTE,
  questionBtn = '',
  schedule = {},
}) {
  return (
    <section
      className={`c-first-section ${isLarge ? 'c-first-section--lg' : 'c-first-section--md'}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-9 col-xl-8">
            {schedule.hasOwnProperty('table') && schedule.hasOwnProperty('clock') ? (
              <div className="c-first-section__schedule">
                <div className="item__wrapper">
                  <IconTable />
                  <span className="c-text-14">{schedule.table}</span>
                </div>
                <div className="item__wrapper">
                  <IconClock />
                  <span className="c-text-14">{schedule.clock}</span>
                </div>
              </div>
            ) : (
              ''
            )}
            <h1 className="c-first-section__title">{title}</h1>
            <p className="c-first-section__description">{description}</p>
            <div className="c-first-section__btns">
              {subsctribeBtn.length ? (
                <Link to={subscribeBtnRoute} className="c-btn c-btn--green">
                  {subsctribeBtn}
                </Link>
              ) : null}

              {questionBtn.length ? (
                <Link to={Routes.QUESIGNIFICAELE} className="c-btn c-btn--white">
                  {questionBtn}
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CFS;
