import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import CFS from '../components/molecules/CFS';
import Collapse from '../components/molecules/Collapse';

import '../assets/styles/pages/alojamiento.scss';

const Alojamiento = () => (
  <Layout
    title="Alojamiento | ELE Barcelona"
    description="Ofrecemos un servicio de alojamiento gratuito para los alumnos que lo necesiten. Te ayudamos a encontrar vivienda a buen precio y bien comunicada con la escuela"
  >
    <div className="alojamiento">
      <CFS title="Alojamiento" />
      <section className="second-section c-faqs-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <p className="second-section__description c-text-18 c-text-18--gray-100">
                Si no eres de Barcelona y necesitas una mano para encontrar alojamiento, ¡No te
                preocupes! Te podemos ofrecer alojamientos a buen precio, cómodos y bien comunicados
                con la escuela. Nuestro servicio de alojamiento es gratuito y opcional.
              </p>
              <Collapse
                title="¿Cuánto es el coste mensual?"
                description="Estarías compartiendo con otros estudiantes, ex-estudiantes o ya profesores que viven aquí. Genial para conocer gente nueva en la ciudad y que además te pueden echar una mano con tus futuras clases. Si lo deseas también puedes compartir piso con extranjeros para poder practicar otros idiomas."
              />
              <Collapse
                title="¿Dónde están los pisos?"
                description="En varios puntos de la ciudad de Barcelona, pero todos muy céntricos y a sólo 20 minutos caminando o en transporte público. También puedes venir en bicicleta – tenemos un párking para bicis justo en frente de la escuela."
              />
              <Collapse
                title="¿Con quién compartiría piso?"
                description="Estarías compartiendo con otros estudiantes, ex-estudiantes o ya profesores que viven aquí. Genial para conocer gente nueva en la ciudad y que además te pueden echar una mano con tus futuras clases. Si lo deseas también puedes compartir piso con extranjeros para poder practicar otros idiomas."
              />
              <Collapse
                title="¿Cómo son los alojamientos?"
                description="Son pisos compartidos entre dos o tres personas, con todas las comodidades de un piso moderno donde tendrás sitio para estudiar, privacidad, wifi, todos los utensilios de cocina, baño compartido y ropa de cama y toallas."
              />
              <Collapse
                title="
                ¿Cómo lo puedo reservar?
                "
                description="Si deseas que te gestionemos un alojamiento antes de comenzar el curso, por favor indícalo en la casilla de “información adicional” cuando completes el formulario de inscripción y lo tendremos todo preparado para ti para cuando empieces."
              />
              <Collapse
                title="¿Cuando me puedo mudar?"
                description="Normalmente organizamos las entradas a los pisos el sábado previo al inicio del curso y la salida el domingo después de a la finalización. También hay la posibilidad de extenderlo antes y después de las fechas del curso, sólo nos lo tienes que indicar en la casilla de “información adicional”."
              />
              <Collapse
                title="¿Me puedo mudar con un/a amigo/a o mi pareja? ¿Puedo traer a mis hijos?"
                description={`Sí, podemos proporcionar alojamientos para dos o más personas. Si este es tu caso, por favor envíanos un e-mail con todos los detalles por separado a <a href="mailto:maria@elebarcelona.com"><strong>maria@elebarcelona.com</strong></a> `}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Alojamiento;
