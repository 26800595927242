import React, { useState } from 'react';

import IconArrow from '../../assets/images/favicon/arrow-green.inline.svg';

const Faq = ({ title, description }) => {
  const [faqOpen, setFaqOpen] = useState(false);

  return (
    <div className={`c-faq ${!faqOpen ? '' : 'c-faq--open'}`} onClick={() => setFaqOpen(!faqOpen)}>
      <h3 className="c-text-16 c-faq__title">
        <div dangerouslySetInnerHTML={{ __html: title }} />
        <div className="c-faq__cross">
          <IconArrow />
        </div>
      </h3>
      <div
        className="c-text-14 c-faq__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default Faq;
